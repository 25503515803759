export const theme = {
	palette: {
		background: {
			default: "#ffffff"
		},
		header: {
			background: "#303952",
			menu: "#dddddd"
		},
		primary: {
			main: "#08a2ed"
		},
		text: {
			primary: "#1c1c36"
		}
	},
	typography: {
		fontFamily: "'Exo 2', sans-serif"
	},
	shape: {
		borderRadius: 0
	},
	shadows: ["none"],
	overrides: {
		MuiCssBaseline: {
			"@global": {
				":root": {
					"--mco-palette-primary-main": "#08a2ed"
				},
				a: {
					color: "#1c1c36"
				}
			}
		}
	}
};
