import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Ui from "@micado-digital/react-ui/components/Ui";
import { theme as customTheme } from "./themes/project";

const ReactTourismRatingSeal01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTourismRatingSeal01" */ "@micado-digital/react-tourism-rating-seal/ReactTourismRatingSeal01"
	)
);

const theme = createTheme(customTheme);

const App = () => {
	const { REACT_APP_PATH } = process.env;

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<Ui>
					<ReactTourismRatingSeal01
						lang={document.documentElement.lang ? document.documentElement.lang : "de"}
						providerID={1}
						url={`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.ProviderApi/ListRatings.api`}
					/>
				</Ui>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("react-rating-seal"));
